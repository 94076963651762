import React from 'react';
import PropTypes from 'prop-types';

/**
 * Wrap a component in this to hide the footer.
 */
export const HidesFooter = props => {
  React.useEffect(() => {
    const toggleFooter = on => {
      const footer = document.querySelector('#footer');
      if (footer) {
        footer.style.display = on ? '' : 'none';
      }
    };

    // Hide footer on mount.
    toggleFooter(false);
    // Show footer on unmount.
    return () => toggleFooter(true);
  }, []);

  return props.children || '';
};


HidesFooter.propTypes = {
  children: PropTypes.any
};
