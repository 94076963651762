import React from 'react';
import PropTypes from 'prop-types';
import { CreateAccountForm } from './create_account_form';
import { signinUrl as routesSigninUrl } from 'utils/routes';
import { withCreateAccountSSORedirect } from './with_create_account_sso_redirect';
import { Homepage } from 'pages/homepage/homepage';
import './create_account.sass';

/**
 * The container component to create an account
 * @property {object} [preFill] - an object containing values to pre-fill in the form data
 */
export const CreateAccount = ({ preFill, token, signinUrl, additionalFormFields }) => {
  return (
    <Homepage
      supplementalBottomContentContainerClassName="c-create-account__bottom-content-container"
    >
      <CreateAccountForm
        token={token}
        preFill={preFill}
        signinUrl={signinUrl || routesSigninUrl()}
        additionalFields={additionalFormFields}
      />
    </Homepage>
  );
};

CreateAccount.propTypes = {
  additionalFormFields: PropTypes.func,
  preFill: PropTypes.object,
  signinUrl: PropTypes.string
};

export const CreateAccountWithSSORedirect = withCreateAccountSSORedirect(CreateAccount);
