import React from 'react';
import { Redirect } from 'react-router';
import CredlyRoute from 'controls/credly_route';
import { LoadingSpinner } from 'controls/loading_spinner';
import { HidesHeader } from 'header';
import { HidesFooter } from 'footer';
import { SignIn } from './signin';
import { SignInAccept } from './signin_accept';
import { SignInForTranscriptDownload } from './sign_in_for_transcript_download';
import { ForgotPassword } from './forgot_password';
import { ChangePassword } from './change_password';
import { CreateAccountEmployment } from 'pages/signin/create_account_employment';
import { CreateAccountWithSSORedirect } from './create_account';
import { oauthUiOptionsAction } from './oauth_ui_options_action';

// Load these two from "." because they're in a different Webpack chunk.
import { CreateAccountAcceptWithSSORedirect, CreateAccountForTranscriptDownload } from '.';
import { CreateAccountChooseSignupOption } from './create_account_choose_sign_up';
import { CreateAccountSSO } from './create_account_sso';
import { AlmostDone } from './almost_done';
import ConfirmYourEmail from './confirm_your_email';
import { TwoFactorAuthenticationSso } from "./two_factor_authentication_sso";
import { CredlySwitch } from 'controls/credly_switch';
import { Homepage } from 'pages/homepage/homepage';
import { routes } from './signin_routes_map';

const SignInWrap = props => {
  let componentToReturn = props.children;
  const params = new URLSearchParams(props.location.search);
  const oauthUiOptionsId = params.get('oauthAppId');
  const [oauthUiOptionsState, fetchOauthUiOptions] =
    oauthUiOptionsAction.useAction('oauthUiOptions', oauthUiOptionsId || 'fake');

  React.useEffect(
    () => {
      if (
        oauthUiOptionsId &&
        !oauthUiOptionsState.resources &&
        !oauthUiOptionsState.status.pending
      ) {
        fetchOauthUiOptions();
      }
    },
    [
      oauthUiOptionsId,
      fetchOauthUiOptions,
      !!oauthUiOptionsState.resources,
      oauthUiOptionsState.status.pending
    ]
  );

  if (oauthUiOptionsId) {
    if (!oauthUiOptionsState.resources) {
      // If an OAuth App Id was passed in and we don't have resources for the UI options yet

      // WHEN pending
      if (oauthUiOptionsState.status.pending) {
        componentToReturn = <LoadingSpinner/>;
      }

      // WHEN failed
      if (oauthUiOptionsState.status.failed) {
        // If the call to fetch UI options fails, just move on with no UI modifiers
        componentToReturn = props.children;
      }
    }

    // All OAuth flows hide the header
    componentToReturn = <HidesHeader>{componentToReturn}</HidesHeader>;

    // And some OAuth flows also hide the footer :)
    // eslint-disable-next-line camelcase
    if (oauthUiOptionsState.resources?.hide_footer) {
      componentToReturn = <HidesFooter>{componentToReturn}</HidesFooter>;
    }
    return componentToReturn;
  } else if (props.location.search.indexOf('onboarding=true') >= 0) {
    return componentToReturn;
  }
  return <Homepage>{props.children}</Homepage>;
};

const ROUTES = [
  {
    path: routes.signin,
    render: routeProps => {
      // startingOauth is set when being redirected to the signin page during the oauth process.
      // Add a url parameter in that case to customize the sign in experience.
      if (initialAppValues.config.startingOauth) {
        initialAppValues.config.startingOauth = false;
        return (
          <Redirect
            to={`${routes.signin}?&oauthAppId=${initialAppValues.config.oauthAppId}`}
          />
        );
      }

      return (
        <SignInWrap {...routeProps}>
          <SignIn uiOptions={initialAppValues.config.oauthUiOptions} twoFactorAuth={routeProps.location.search.indexOf('twoFactorAuth') >= 0} />
        </SignInWrap>
      );
    },
    title: 'Sign In',
    anonymous: true
  },
  {
    path: routes.tfa,
    render: routeProps => <SignInWrap {...routeProps}><SignIn twoFactorAuth /></SignInWrap>,
    title: 'Sign In',
    anonymous: true
  },
  {
    path: routes.tfaSso,
    render: routeProps => <SignInWrap {...routeProps}><TwoFactorAuthenticationSso /></SignInWrap>,
    title: 'Sign In',
    anonymous: true
  },
  {
    path: routes.forgotPassword,
    render: routeProps => <SignInWrap {...routeProps}><ForgotPassword /></SignInWrap>,
    title: 'Sign In',
    anonymous: true
  },
  {
    path: routes.editPassword,
    render: routeProps => <SignInWrap {...routeProps}><ChangePassword /></SignInWrap>,
    title: 'Reset Password',
    anonymous: true
  },
  {
    path: routes.acceptViaSignin,
    render: routeProps => (
      <SignInAccept
        badgeId={routeProps.match.params.id}
        twoFactorAuth={routeProps.location.search.indexOf('twoFactorAuth') >= 0}
      />),
    title: 'Sign In to Accept Your New Badge',
    anonymous: true
  },
  {
    path: routes.accept,
    render: routeProps => (
      <CreateAccountAcceptWithSSORedirect badgeId={routeProps.match.params.id} />
    ),
    title: 'Sign Up to Accept Your New Badge',
    anonymous: true
  },
  {
    path: routes.acceptViaChooseSignup,
    render: routeProps => (
      <CreateAccountChooseSignupOption badgeId={routeProps.match.params.id} />
    ),
    title: 'Sign Up to Accept Your New Badge',
    anonymous: true
  },
  {
    path: routes.acceptViaSignUp,
    render: routeProps => (
      <CreateAccountAcceptWithSSORedirect badgeId={routeProps.match.params.id} />
    ),
    title: 'Sign Up to Accept Your New Badge',
    anonymous: true
  },
  {
    path: routes.acceptEmploymentViaSignUp,
    render: routeProps => {
      return <CreateAccountEmployment nonceToken={routeProps.match.params.nonceToken}/>;
    },
    title: 'Sign Up to connect to your employer.',
    anonymous: true
  },
  {
    path: routes.signUp,
    render: _routeProps => <CreateAccountWithSSORedirect />,
    title: 'Create Account',
    anonymous: true
  },
  {
    path: routes.almostDone,
    render: _routeProps => <AlmostDone />,
    title: 'Confirm Email',
    anonymous: true
  },
  {
    path: routes.confirmYourEmail,
    render: routeProps => (
      <ConfirmYourEmail
        userId={routeProps.match.params.id}
      />
    ),
    title: 'Confirm Email',
    anonymous: true
  },
  // Sign-up screen from an SSO token
  {
    path: routes.sso,
    render: _routeProps => <CreateAccountSSO />,
    title: 'Sign Up',
    anonymous: true
  },
  {
    path: routes.transcriptViaSignUp,
    render: routeProps => (
      <CreateAccountForTranscriptDownload
        userVanitySlug={routeProps.match.params.user_vanity_slug}
        transcriptRecipientId={routeProps.match.params.id}
      />
    ),
    title: 'Sign Up to Download Transcript',
    anonymous: true
  },
  {
    path: routes.transcriptViaSignIn,
    render: routeProps => (
      <SignInForTranscriptDownload
        twoFactorAuth={routeProps.location.search.indexOf('twoFactorAuth') >= 0}
        userVanitySlug={routeProps.match.params.user_vanity_slug}
        transcriptRecipientId={routeProps.match.params.id}
      />
    ),
    title: 'Sign In to Download Transcript',
    anonymous: true
  }
];

export const SignInRoutes = function SignInRoutes(props) {
  return (
    <CredlySwitch>
      {ROUTES.map(r =>
        <CredlyRoute
          key={r.path}
          path={r.path}
          render={r.render}
          title={r.title}
          anonymous={r.anonymous}
        />
      )}
    </CredlySwitch>
  );
};

SignInRoutes.matches = ROUTES.map(r => r.path);
